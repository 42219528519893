import React from 'react';
import {useCss} from "react-use";
import knkFarmHausImage from "../../images/KnKFarmHaus50.png";

export default ({ route }) => {


    console.log(route);

    const imageClass = useCss({
        height: '50%',
        width: '50%',
        margin: 'auto'
    })
    
    return (
        <div className="page-wrapper">
            <span>Thanks for visiting us!!!  My name is Kurt and my wife's name is Karen, and we are</span>
            <img className={imageClass} src={knkFarmHausImage}/>
        </div>
    );
}

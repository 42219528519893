import React, {useEffect, useState} from 'react';
import {OktaAuth, TokenParams} from '@okta/okta-auth-js';
import {oktaConfig} from "../../utils/constants";

const MembersPage = () => {


    const authClient = new OktaAuth(oktaConfig);
    const tokenManager = authClient.tokenManager;

    const [accessToken, setAccessToken] = useState(null);
    const [idToken, setIdToken] = useState(null);
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {

        const tokenFn = async () => {
            const accessToken = await tokenManager.get('accessToken');
            const idToken = await tokenManager.get('idToken');
            const userInfo = await authClient.getUser(accessToken);

            console.log(userInfo);

            setAccessToken(accessToken);
            setIdToken(idToken);
            setUserInfo(userInfo);

        }

        tokenFn();
    })

    if (!userInfo) {
        authClient.token.getWithRedirect();
    }

    //todo: refactor css
    const userText = accessToken ? <div><p>You are signed in!</p><button onClick={ () =>{} }>Logout</button></div>
        : <div><p>You need to sign in to use the application!</p><button onClick={ () =>{} }>Sign In</button></div>;

        return <div className="page-home"><h1>Welcome to Books with Hooks</h1>{ userText }</div>;
}

export default MembersPage;
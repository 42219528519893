import React from 'react';

export default props => {
    return (
        <div className="page-wrapper">
            <h1>This is my public page</h1>
            <h2>Various content will be displayed</h2>
        </div>
    );
}

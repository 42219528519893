import React from "react";
import {HashRouter as Router} from 'react-router-dom';
import {useSpring, animated } from "react-spring";
import {Helmet} from "react-helmet";
import {NoPageFound} from "./content/pages";
import PageHeader from "./content/page-header";

import favicon from './images/KnKFarmHaus50.png';
import './app.scss';
import {ROUTES} from './routes';
import PageFooter from "./content/page-footer";

const App = () => {

    const animProps = useSpring({opacity: 1, from: {opacity: 0}, config: {duration: 500}});
    const resultRoutes = Object.keys(ROUTES).map(key => ROUTES[key]());

    return (
        <Router>
            <Helmet>
                <title>KnK Farm Haus</title>
                <link rel="icon"  type="image/png" href={favicon} />
                <meta charSet="utf-8"/>
                <meta http-equiv="Pragma" content="no-cache"/>
                <meta http-equiv="Expires" content="0"/>
            </Helmet>
                <PageHeader />
                <animated.div style={animProps} className="application-wrapper">
                    {resultRoutes || <NoPageFound />}
                </animated.div>
            <PageFooter />
    </Router>
    );
};

export default App;

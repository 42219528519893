import React from 'react';
import MainMenu from './main-menu';

const PageHeader = () => {

    return <>
        <MainMenu />
    </>
}

export default PageHeader;
import React from 'react';
import {Route, useParams, useRouteMatch, Switch, Link} from "react-router-dom";
import TopicContentWrapper from '../topic-content-wrapper';



const TOPICS = [
    {
        label: 'Latest News',
        url: 'sitrep'
    },
    {
        label: 'ComNavQuackPac',
        url: 'arrg-matey'
    },
    {
        label: '22nd Chickenborne',
        url: 'chickens-inc'
    },
    {
        label: '4th Alpacarmory Regiment',
        url: 'a-company'
    }
]


const FarmPage = () => {

    const {path, url} = useRouteMatch();

    console.log(path, url);

    return <>
    <ul>
        {TOPICS.map(topic => <Link to={`${url}/${topic.url}`}>{topic.label}</Link>)}
    </ul>
        <Switch>
            <Route path={`${path}/:topic`}>
                <TopicContentWrapper />
            </Route>
        </Switch>
    </>
}


export default FarmPage;
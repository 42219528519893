
export const ROUTE_KEYS = {
    about: '/about',
    admin: '/admin',
    contact: '/contact',
    farm: '/farm',
    fimfd: '/fimfd',
    home: '/',
    media: '/media',
    members: '/login',
    noroute: '*',
    planner: '/planner',
    authCallback: '/auth/callback'

}


export const oktaConfig = {
    issuer: 'https://knkfarmhaus/oauth2/default',
    clientId: '0oa23a9erogUW0WNR5d7',
    redirectUri: '/auth/callback',
    tokenManager: {
        storage: 'sessionStorage'
    }
}
import React from 'react';
import { SecureRoute, Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import {useHistory} from "react-router-dom";


const AdminPage = props => {

    const config = {
        // Required config
        issuer: 'https://dev-80085043.okta.com/oauth2/default',
        // Required for login flow using getWithRedirect()
        clientId: '0oa23a9erogUW0WNR5d7',
        redirectUri: 'https://localhost:8443/admin',
        // Parse authorization code from hash fragment instead of search query
        responseMode: 'fragment',

        // Configure TokenManager to use sessionStorage instead of localStorage
        tokenManager: {
            storage: 'sessionStorage'
        },
        restoreOriginalUri: async (oktaAuth, originalUri) => {
            // redirect with custom router
            router.replace({
                path: toRelativeUrl(originalUri, baseUrl)
            });
        }
    };

    const oktaAuth = new OktaAuth(config);

    (async () => { const result = await oktaAuth.handleLoginRedirect(); console.log(result)})

    if (oktaAuth.isLoginRedirect()) {
        try {
            (async () => {await oktaAuth.handleLoginRedirect()})
        } catch (e) {
            // log or display error details
        }
    }

    return <div className="page-wrapper">Admin</div>
};

export default AdminPage;

import {ROUTE_KEYS} from "./utils/constants";
import {AboutPage, AdminPage, ContactPage, FarmPage, HomePage, MediaPage, MembersPage, FimfdPage} from "./content/pages";
import React from "react";
import {Route} from "react-router-dom";
import GardenPlanner from "./content/pages/garden-planner";

export const ROUTES = {
    [ROUTE_KEYS.admin]: () => <Route exact path={ROUTE_KEYS.admin}><AdminPage/></Route>,
    [ROUTE_KEYS.about]: (props) => <Route exact path={ROUTE_KEYS.about}><AboutPage props={props}/></Route>,
    [ROUTE_KEYS.contact]: () => <Route exact path={ROUTE_KEYS.contact}><ContactPage/></Route>,
    [ROUTE_KEYS.farm]: () => <Route path={ROUTE_KEYS.farm}><FarmPage /></Route>,
    [ROUTE_KEYS.home]: () => <Route exact path={ROUTE_KEYS.home}><HomePage/></Route>,
    [ROUTE_KEYS.media]: () => <Route exact path={ROUTE_KEYS.media}><MediaPage/></Route>,
    [ROUTE_KEYS.members]: () => <Route exact path={ROUTE_KEYS.members}><MembersPage/></Route>,
    [ROUTE_KEYS.fimfd]: () => <Route exact path={ROUTE_KEYS.fimfd}><FimfdPage /></Route>,
    [ROUTE_KEYS.planner]: () => <Route exact path={ROUTE_KEYS.planner}><GardenPlanner /></Route>,
    [ROUTE_KEYS.authCallback]: () => {
    }
};
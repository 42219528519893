import React from 'react';
import {useRouteMatch} from "react-router-dom";

const TopicContentWrapper = props => {

    let { path, url} = useRouteMatch();

    return(<div>Topic Content Wrapper: ${path} ${url}</div>)

};

export default TopicContentWrapper;
import React from 'react';
import {ROUTE_KEYS} from "../utils/constants";
import knkFarmHausImage from "../images/KnKFarmHaus.ico";
import {Link} from "react-router-dom";

const MainMenu = () => {

    return <nav>
        <ul className="nav-list">
            <li><Link to={ROUTE_KEYS.home}><img className="logo-image-header" src={knkFarmHausImage} /></Link></li>
            <li><Link to={ROUTE_KEYS.farm}>Our Farm</Link></li>
            <li><Link to={ROUTE_KEYS.members}>Members</Link></li>
            <li><Link to={ROUTE_KEYS.media}>Media</Link></li>
            <li><Link to={ROUTE_KEYS.contact}>Contact</Link></li>
            <li><Link to={ROUTE_KEYS.about}>About</Link></li>
            <li><Link to={ROUTE_KEYS.admin}>Admin</Link></li>
            <li><Link to={ROUTE_KEYS.fimfd}>FIMFD - iframe</Link></li>
            <li><a onClick={() => {window.location.href = 'http://fimfd.com'}}>FIMFD</a></li>
            <li><Link to={ROUTE_KEYS.planner}>Planner</Link></li>
        </ul>
    </nav>
}

export default MainMenu;
import React from 'react';
import './garden-planner.scss';
import {Box, Tabs, Tab} from "@material-ui/core";
import TabPanel from "../components/TabPanel";

import BedsComponent from '../components/beds-component';

const GardenPlanner = props => {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Configure Beds" {...a11yProps(0)} />
                    <Tab label="Configure Planting" {...a11yProps(1)} />
                    <Tab label="Results and Schedule" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <BedsComponent />
            </TabPanel>
            <TabPanel value={value} index={1}>
                Item Two
            </TabPanel>
            <TabPanel value={value} index={2}>
                Item Three
            </TabPanel>
        </Box>
    );
};


export default GardenPlanner;

import {applyMiddleware, combineReducers, createStore} from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import userReducer from './reducers/user-reducer';
import { reducer as formReducer } from 'redux-form';

const reducers = combineReducers({
    form: formReducer,
    user: userReducer
});

export default function configureStore(preloadedState) {

    const middlewares = [thunkMiddleware];
    const middlewareEnhancer = applyMiddleware(...middlewares);

    const enhancers = [middlewareEnhancer];
    const composedEnhancers = composeWithDevTools(...enhancers);

    return createStore(reducers, preloadedState, composedEnhancers);

}

import HomePage from './home-page';
import PublicPage from './public-page';
import AdminPage from './admin-page';
import AboutPage from './about-page';
import NoPageFound from './no-page-found';
import ContactPage from "./contact-page";
import FarmPage from "./farm-page";
import MediaPage from "./media-page";
import MembersPage from "./members-page";
import FimfdPage from "./fimfd-page";

export {
    AboutPage,
    AdminPage,
    ContactPage,
    FarmPage,
    FimfdPage,
    HomePage,
    MediaPage,
    MembersPage,
    NoPageFound,
    PublicPage
}

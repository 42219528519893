import React from 'react';
import Iframe from 'react-iframe';

const FimfdPage = props => {

    return (

        <Iframe url="http://www.fimfd.com"
                position="absolute"
                width="100%"
                id="myId"
                className="myClassname"
                height="100%"
                styles={{height: "25px"}}/>
    )
}

export default FimfdPage;